@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.phonemaindiv {
  padding: 70px;
  background-color: #32474a;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  
  flex-direction: column;
  margin-bottom: 60px;
}
.bolllofdiv {
  display: flex;
  justify-content: flex-end;
  margin-top: -129px;
}
.phoneworksdiv {
  display: flex;
  flex-direction: column;
}
.phoneheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  text-align: center;
}
.phonepara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-top: 25px;
}
.phoneareamaindiv {
  background-image: url(../Images/circle.svg);
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: contain;
}
.phoneareamaindivsecond {
  background-image: url(../Images/circle.svg) !important;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: contain;
  width: 100%;
}
.marker {
  background-image: url(../../Page2/Images/line.svg);
  width: 3px;
  background: linear-gradient(180.25deg, #89aa8b -3.14%, #fff3e9 113.24%);
  height: 518px;
  position: relative;
  text-align: center;
  margin: auto;
}
.digitheader {
  box-sizing: border-box;
  position: absolute;
  top: 230px;
  right: -55px;
}
.digithead {
  margin: auto;
  text-align: center;
  color: #32474a;
  font-family: "Poppins";
  font-style: normal;
}
.digitheaderbelow {
  box-sizing: border-box;
  position: absolute;
  top: 230px;
  right: -35px;
}
.digitheadbelow {
  margin: auto;
  text-align: center;
  color: #32474a;
  font-family: "Poppins";
  font-style: normal;
  width: 95px;
  height: 75px;
}
.paragraphphonediv1 {
  background-image: url(../../Page2/Images/grop.svg);
  border-radius: 20px;
  background-size: cover !important;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  margin-top: 30px;
  padding: 60px;
}
.paragraphphonediv2 {
  background-image: url(../../Page2/Images/grop2.svg);
  border-radius: 20px;
  background-size: cover !important;
  margin-top: 30px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  padding: 60px;
}
.paraphone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  margin-right: 10px;
}
.paraphonetwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  margin-left: 10px;
}
.phonepart {
  margin-top: 50px;
}
.phoneimage {
  text-align: center;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 1840px) {
  .flow .headermbll {
    width: 100%;
    max-width: 390px;
    position: relative;
    top: 50px;
  }
}
@media only screen and (min-width: 2246px) {
  .flow .headermbll {
    width: 100%;
    max-width: 390px !important;
    position: relative;
    top: 250px;
  }
}
@media only screen and (min-width: 2600px) {
  .flow .headermbll {
    width: 100%;
    max-width: 390px !important;
    position: relative;
    top: 70px;
  }
}
@media only screen and (min-width: 1400px) {
  .paragraphphonediv2 {
    background-position: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .bolllofdiv {
    display: none;
  }
  .paragraphphonediv2 {
    background-position: 0px;
  }
  .phonemaindiv {
    padding: 40px;
  }
  .paraphone {
    font-weight: 400;
    font-size: 10px;
    padding: 20px;
  }
  .paraphonetwo {
    font-weight: 400;
    font-size: 10px;
    padding: 20px;
  }
  .paragraphphonediv1,
  .paragraphphonediv2 {
    padding: 20px;
  }
  .phoneheading {
    font-weight: 650;
    font-size: 33px;
    line-height: 37px;
  }
  .phonepara {
    font-weight: 350px;
    font-size: 15px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .paragraphphonediv2 {
    background-position: 0px;
  }
  .marker {
    height: 300px;
  }
  .phoneheading {
    font-weight: 634;
    font-size: 31px;
    line-height: 31px;
  }
  .phonepara {
    font-weight: 300px;
    font-size: 13px;
    line-height: 20px;
  }
  .phonemaindiv {
    padding: 40px;
  }
  .paraphone {
    font-size: 12px;
    padding: 8px;
    font-weight: 310;
  }

  .paraphonetwo {
    font-size: 12px !important;
    padding: 8px;
    font-weight: 310;
  }
  .paragraphphonediv1,
  .paragraphphonediv2 {
    padding: 25px;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .phonesize {
    width: 150px;
    height: 300px;
  }
  .digitheaderbelow {
    top: 128px;
    right: -39px;
  }
  .digitheadbelow {
    margin: auto;
    text-align: center;
    color: #32474a;
    font-family: "Poppins";
    font-style: normal;
  }
  .digitheader {
    box-sizing: border-box;
    position: absolute;
    top: 127px;
    right: -39px;
  }
  .digitheadbelowimage {
    width: 65px;
    height: 50px;
  }
  .digitheaderbelow {
    top: 128px;
    right: -39px;
  }
  .digitheadbelowimagebelow {
    width: 65px;
    height: 50px;
  }
}
@media only screen and (max-width: 550px) {
  .paragraphphonediv2 {
    background-position: 0px;
  }
  .phoneheading {
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
  }
  .phonepara {
    font-weight: 300px;
    font-size: 11px;
    line-height: 18px;
  }
  .phonemaindiv {
    padding: 30px;
  }
  .paragraphphonediv1,
  .paragraphphonediv2 {
    padding: 12px !important;
  }
  .paraphone,
  .paraphonetwo {
    font-size: 9px;
    padding: 8px;
    font-weight: 280;
  }
  .paragraphphonediv1,
  .paragraphphonediv2 {
    padding: 20px;
    border-radius: 10px;
  }
  .phonesize {
    width: 120px;
    height: 260px;
  }
}
@media only screen and (max-width: 425px) {
  .digitheader {
    box-sizing: border-box;
    position: absolute;
    top: 127px;
    right: -33px;
  }
  .digitheadbelowimage {
    width: 59px;
    height: 45px;
  }
  .digitheaderbelow {
    top: 128px;
    right: -39px;
  }
  .digitheadbelowimagebelow {
    width: 59px;
    height: 45px;
  }
  .paraphone,
  .paraphonetwo {
    font-size: 9px;
    padding: 6px;
    font-weight: 280;
  }
  .phoneareamaindiv {
    margin-top: 50px;
  }
  .phoneareamaindivsecond {
    margin-top: 50px;
  }

  .paragraphphonediv1,
  .paragraphphonediv2 {
    padding: 10px;
    border-radius: 10px;
    margin-left: 5px;
  }

  .paragraphphonediv2 {
    background-position: 0px;
  }
  .paraphone,
  .paraphone {
    font-size: 9px;
    text-align: initial;
    align-items: center;
    justify-content: center;
  }
  .phonesize {
    width: 80px;
    height: 170px;
  }
}
