@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.phonemaindiv {
  background-color: #32474a;
  width: 100%;
  justify-content: center;
  display: flex;

  .phonemaindiv {
    padding: 70px;
    width: 96rem;
    margin-bottom: 60px !important;
  }
  .phoneworksdiv {
    display: flex;
    flex-direction: column;
  }
  .phoneheading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    text-align: center;
  }
  .phonepara {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400px;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    margin-top: 25px;
  }
  .phoneareamaindiv {
    background-image: url(../../Page1/Images/circle.svg);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
  }
  .marker {
    background-image: url(../Images/line.svg);
    width: 3px;
    background: linear-gradient(180.25deg, #89aa8b -3.14%, #fff3e9 113.24%);
    height: 518px;
    position: relative;
    text-align: center;
    margin: auto;
  }
  .markerheader {
    position: absolute;
    top: 230px;
    right: -57px;
  }
  .markerhead {
    margin: auto;
    text-align: center;
    color: #32474a;
    top: calc(50% - 44px / 2 - 1916.5px);
    font-family: "Poppins";
    font-style: normal;
    position: relative;
  }
  .markerheaderbelow {
    position: absolute;
    top: 230px;
    right: -35px;
  }
  .markerheadbelow {
    margin: auto;
    text-align: center;
    color: #32474a;
    top: calc(50% - 44px / 2 - 1916.5px);
    font-family: "Poppins";
    font-style: normal;
    position: relative;
  }
  .paragraphphonediv1 {
    background-image: url(../Images/grop.svg);
    border-radius: 20px;
    background-size: cover !important;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    margin-top: 30px;
    padding: 60px;
  }
  .paragraphphonediv2 {
    background-image: url(../Images/grop2.svg);
    border-radius: 16px;
    background-size: cover !important;
    margin-top: 30px;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    padding: 60px;
  }
  .paraphone {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-right: 10px;
    text-align: left;
  }
  .paraphonenmbr2 {
    font-family: "Poppins";
    text-align: left !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-left: 12px;
  }
  .phonepart {
    margin-top: 50px;
  }
  .phoneimage {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .phoneareamaindivsecond {
    background-image: url(../../Page1/Images/circle.svg) !important;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
    width: 100%;
  }
  @media only screen and (min-width: 1400px) {
    .paragraphphonediv2 {
      background-position: 0px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .paragraphphonediv2 {
      background-position: 0px;
    }
    .phonemaindiv {
      padding: 40px;
      margin-bottom: 60px;
    }
    .paraphone {
      font-weight: 400;
      font-size: 16px;
      padding: 30px;
      color: #ffffff;
    }
    .paraphonenmbr2 {
      font-weight: 400;
      font-size: 16px;
      padding: 20px;
    }
    .paragraphphonediv1,
    .paragraphphonediv2 {
      padding: 20px;
    }
    .phoneheading {
      font-weight: 650;
      font-size: 33px;
      line-height: 37px;
    }
    .phonepara {
      font-weight: 350px;
      font-size: 15px;
      line-height: 24px;
    }
    .paraphone {
      font-weight: 350;
      font-size: 15px;
    }
    .paraphonenmbr2 {
      font-weight: 350;
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 768px) {
    .digitheadbelowimage {
      width: 65px;
      height: 50px;
    }
    .paragraphphonediv2 {
      background-position: 0px;
    }
    .marker {
      height: 300px;
    }
    .phoneheading {
      font-weight: 634;
      font-size: 31px;
      line-height: 31px;
    }
    .phonepara {
      font-weight: 300px;
      font-size: 13px;
      line-height: 20px;
    }
    .phonemaindiv {
      padding: 40px;
      margin-bottom: 60px;
    }
    .paraphone {
      font-size: 12px;
      padding: 8px;
      font-weight: 310;
    }
    .paraphonenmbr2 {
      font-size: 12px;
      padding: 8px;
      font-weight: 310;
    }
    .paragraphphonediv1,
    .paragraphphonediv2 {
      padding: 25px;
      margin: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .paraphone {
      font-size: 13px;
    }
    .paraphonenmbr2 {
      font-size: 12px;
    }
    .phonesize {
      width: 150px;
      height: 300px;
    }

    .markerheader {
      position: absolute;
      top: 132px;
      right: -12px;
    }
    .markerhead {
      height: 38px;
      width: 38px;
    }
    .markerheaderbelow {
      position: absolute;
      top: 132px;
      right: -24px;
    }
  }
  @media only screen and (max-width: 550px) {
    .paragraphphonediv2 {
      background-position: 0px;
    }
    .phoneheading {
      font-weight: 600;
      font-size: 26px;
      line-height: 28px;
    }
    .phonepara {
      font-weight: 300px;
      font-size: 11px;
      line-height: 18px;
    }
    .phonemaindiv {
      padding: 30px;
      margin-bottom: 60px;
    }
    .paragraphphonediv1,
    .paragraphphonediv2 {
      padding: 12px !important;
    }
    .paraphone {
      font-size: 9x;
      padding: 8px;
      font-weight: 280;
    }
    .paraphonenmbr2 {
      font-size: 9x;
      padding: 5px;
      font-weight: 280;
    }
    .paragraphphonediv1,
    .paragraphphonediv2 {
      padding: 20px;
      border-radius: 10px;
    }

    .phonesize {
      width: 120px;
      height: 260px;
    }

    .markerheader {
      position: absolute;
      right: -5px;
    }
    .markerhead {
      height: 35px;
      width: 35px;
    }
    .digit {
      position: absolute;
      bottom: 0px;
      right: 12px;
    }
  }
  @media only screen and (max-width: 425px) {
    .digitheadbelowimage {
      width: 59px;
      height: 45px;
    }
    .paraphone {
      font-size: 8x;
      padding: 6px;
      font-weight: 280;
      text-align: left !important;
    }
    .phoneareamaindiv {
      margin-top: 50px;
    }
    .paragraphphonediv1,
    .paragraphphonediv2 {
      padding: 10px;
      border-radius: 10px;
      margin-left: 5px;
    }

    .paragraphphonediv2 {
      background-position: 0px;
    }
    .paraphonenmbr2 {
      font-size: 8px;
      text-align: left !important;
      padding: 4px !important;
    }
    .phonesize {
      width: 100%;
      height: 245px;
    }
  }
}
