@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.maindiv {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  background-image: url(../Images/patter.svg);
  background: #fff3e9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  min-height: 755px;
  height: 100vh;
}
.italy {
  display: flex;
  background-image: url(../Images/blue.svg);
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  background-position: center;
  background-size: cover;
  align-items: center;
  margin: 30px;
  margin-top: 45px;
}
.heading1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: -18px;
}
.hostbtnimg {
  width: 357px;
  height: 46px;
}
.hostbtns {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}
@media screen and (max-width: 991px){
  .hostbtns{
    justify-content: center;
  }
}
.btn1 {
  height: 50px;
  width: 154px;
  border-radius: 5px;
  background-color: black;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.googleheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}
.avail {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  margin-left: 30px;
}
.playdiv {
  display: flex;
}
.googlediv {
  display: flex;
}
.googleimg {
  /* position: absolute; */
  width: 30px;
  height: 30px;
}
.appleimg {
  width: 30px;
  height: 35px;
}
.para1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  margin-top: 20px;
  font-family: "Poppins";
  color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.googleheading {
  font-size: 20px;
  line-height: 28px;
  font-family: "Roboto";
}
.appleheading {
  font-size: 20px;
  line-height: 28px;
  font-family: "Roboto";
}
.cookimage {
  width: 800px;
}

/* media query */
@media only screen and (max-width: 1350px) {
  .hostbtnimg {
    width: 300px;
    height: 40px;
  }
}

@media only screen and (max-width: 1100px) {
  .cookimage {
    width: 450px;
  }
  .maindiv {
    gap: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .maindiv {
    gap: 20px;
  }
  .heading1 {
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 15px !important;
  }
  .para1 {
    font-size: 14px;
    font-weight: 370;
    line-height: 25px;
    margin-top: 10px;
  }
  .btn1 {
    height: 40px;
    width: 134px;
  }
  .googleheading {
    font-size: 15px;
    line-height: 22px;
  }
  .googleimg {
    width: 23px;
    height: 23px;
  }
  .appleheading {
    font-size: 15px;
    line-height: 22px;
  }
  .appleimg {
    width: 23px;
    height: 23px;
  }
  .cookimage {
    width: 550px !important;
  }
}
@media only screen and (max-width: 768px) {
  .hostbtns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .hostbtnimg {
    width: 260px;
    height: 30px;
  }
  .maindiv {
    gap: 15px;
  }
  .cookimage {
    width: 400px !important;
  }
  .heading1 {
    font-size: 20px;
    font-weight: 450px;
    line-height: 32px;
    margin-bottom: -15px;
  }
  .para1 {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    margin-top: 5px;
  }
  .btn1 {
    height: 30px;
    width: 100px;
  }
  .googleheading {
    font-size: 12px;
    line-height: 16px;
  }
  .googleimg {
    width: 20px;
    height: 20px;
  }
  .appleheading {
    font-size: 11px;
    line-height: 16px;
  }
  .appleimg {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .hostmaindiv {
    margin-top: 30px;
  }
  .heading1 {
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: -14px;
  }
  .cookimage {
    width: 450px;
  }
  .maindiv {
    gap: 20px;
  }
  .maindiv {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    width: 100%;
  }
  .italy {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    width: 100%;
  }
}
@media only screen and (max-width: 1650px) {
  .maindiv {
    gap: 20px;
  }
}
@media only screen and (max-width: 1440px) {
  .maindiv {
    gap: 30px;
  }
  .cookimage {
    width: 70%;
  }
  .heading1 {
    font-size: 40px;
    font-weight: 450px;
    line-height: 36px;
    margin-bottom: -10px;
  }
  .para {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }

  .cookimage {
    width: 600px;
  }
}
@media only screen and (max-width: 450px) {
  .heading1 {
    font-size: 32px;
    font-weight: 450px;
    line-height: 30px;
    margin-bottom: 5px !important;
  }
  .para {
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
  .cookimage {
    width: 350px !important;
  }
  .hostmaindiv{
    margin-top: 125px;
  }
}
@media only screen and (max-width: 425px) {
  .cookimage {
    width: 300px !important;
  }
  .hostmaindiv {
    margin-top: 25px;
  }
}
