.main_faq {
  height: 100vh;
  background: #ffffff !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:600px){
    height: 100%;
  }
  .accordion_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  
  .faq_title {
    font-family: "Inter";
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #32474a;
margin-top: 30px;
@media(max-width:900px){
  font-size: 28px;
}
@media(max-width:400px){
  font-size: 20px !important;
  // margin-top: 100px !important;
}

  }

  .faq_number {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 36px !important;
    line-height: 44px !important;
    color: #32474a !important;
    @media (max-width: 1500px) {
      font-size: 21px !important;
      // padding-top: 0.6rem !important;
    }
    @media (max-width: 900px)  {
      // padding-top: 0.6rem !important;
      font-size: 18px !important;
      margin-right: 5px;
    }
    @media (min-width: 451px)and (max-width:899px) {
      padding-top: 0.3rem !important;
      font-size: 15px !important;
    }
    @media (max-width: 450px) {
      font-size: 13px !important;
      // padding-top: 0rem !important;
    }
  }
.main_faq {

  .icon {
    border-radius: 50%;
    background-color: #32474a;
    color: white;
    padding: 1px;
    width: 42px ;
    height: 42px;

   
    @media (max-width: 1300px) {
      width:35px !important ;
      height:35px  !important;
    }
    @media (max-width: 900px) {
      width:35px !important ;
      height:35px  !important;
    }
    @media (max-width: 670px) {
      width: 25px !important;
      height: 25px !important;
    }
  }
}

 

  .MuiCollapse-root {
    background-color: #fff3e9;

    .MuiTypography-root {
      margin: 0;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      font-weight: 400 !important;
      font-size: 1rem !important;
      color: black !important;
      line-height: 1.5 !important;
      letter-spacing: 0.00938em !important;
padding-left: 5% !important;
      @media (max-width: 1250px) {
        font-size: 13px !important;
      }
      @media (max-width: 900px) {
        font-size: 10px !important;
      }
      @media (max-width: 670px) {
        font-size: 10px !important;
      }
      @media (max-width: 450px) {
        font-size: 7px !important;
      }
    }
  }

  .MuiPaper-rounded {
    // height: 99px !important;
    
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    @media (min-width: 601px) {
      width: 70% !important;
    }
    @media (max-width: 600px) {
      width: 85% !important;
    }
    .MuiAccordionSummary-root {
      .MuiAccordionSummary-content {
        margin-top: 0!important;
        padding: 12px 0;
        //  .MuiTypography-body1 {

        //    font-size: 40px !important;
        //   }
      }
    }
  }

  .faq_question {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 44px !important;
    color: #131313 !important;
    padding: 1rem 0 !important;
    margin-right: 15px;
    @media (max-width: 1500px) {
      font-size: 25px !important;
      padding: 0.8rem 0 !important;
      line-height: 40px !important;
    }
    @media (max-width: 900px)  {
      font-size: 18px !important;
      padding: 0.5rem 0 !important;
      line-height: 35px !important;
    }
    @media (min-width: 451px)and (max-width:899px) {
      font-size: 15px !important;
      padding: 0rem 0 !important;
      line-height: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 450px) {
      font-size: 13px !important;
      padding: 0.7rem 0 !important;
      line-height: 20px !important;
      margin-left: 5px;
    }
  }
}




 .accordion_flex > .MuiPaper-root{
box-shadow: none;
background-color: #F9F9F9;
border-radius: 10px !important;
::before{
 height: 0px;
}
 }
 .MuiPaper-root::before{
   height: 0px !important;
 }