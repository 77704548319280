@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.main_problem {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  min-height: 730px;

  .p_img {
    max-width: 50%;
    @media (max-width: 1500px) {
      width: 700px;
    }
    @media (max-width: 1000px) {
      width: 700px;
    }

    @media (max-width: 1280px) {
      width: 500px;
    }

    @media (max-width: 550px) {
      width: 400px;
    }
    @media (max-width: 550px) {
      width: 300px;
    }
  }

  .problem_content {
    margin-top: 50px;
    
    border-radius: 60px;
    background: url(../Images/bg404.svg);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    width: 1250px;
    position: relative;
    height: 100vh;
    background-size: contain;

    @media (max-width: 1680px) {
      width: 1400px;
    }
    @media (max-width: 1480px) {
      width: 1200px;
    }
    @media (max-width: 1280px) {
      width: 1000px;
    }
    @media (max-width: 1080px) {
      width: 900px;
    }
    @media (max-width: 700px) {
      height: 1000px;
    }
    @media (max-width: 1000px) {
      width: 100%;
      height: 100vh;
      background-size: cover;
    }

    .p_relate {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    // @media (max-width: 700px) {
    //   height: 1000px;
    // }
    // @media (max-width: 550px) {
    //   height: 750px;
    // }
    // @media (max-width: 400px) {
    //   height: 550px;
    // }

    .p_title {
      padding: 30px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #000000;

      @media (max-width: 900px) {
        font-size: 28px;
      }

      @media (max-width: 470px) {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
      }
    }

    .p_para {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #202020;
      max-width: 70%;
      @media (max-width: 1700px) {
        max-width: 70%;
      }
      @media (max-width: 1300px) {
        max-width: 70%;
      }
      @media (max-width: 1080px) {
        max-width: 90%;
      }
      @media (max-width: 900px) {
        font-size: 14px;
      }

      @media (max-width: 550px) {
        max-width: 80%;
      }

      @media (max-width: 400px) {
        font-size: 13px;
      }
    }
  }

  .bcircle {
    position: absolute;
    left: -110px;
    bottom: 76px;
    @media (max-width: 1680px) {
      bottom: 110px;
    }
    @media (max-width: 1480px) {
      bottom: 200px;
    }
    @media (max-width: 1280px) {
    }
    @media (max-width: 1080px) {
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
}
