@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.main_footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
overflow: hidden !important;
  .footer {
    // background: url(../Images/footer.png);
    height: 382px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    max-width: 100%;

    background: #fff3e9;
    mix-blend-mode: normal;
    border-radius: 0px 141px 0px 0px;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    position: relative;

    @media (max-width: 1130px) {
      border-radius: 0;
    }
    @media (max-width: 1000px) {
      height: auto;
      padding-bottom: 50px;
    }
    @media (max-width: 600px) {
     height: auto;
    }

    .footer_title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "PT Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 56px;

      color: #32474a;

      @media (max-width: 800px) {
        font-size: 30px;
      }
    }

    .footer_title img {
      @media (max-width: 800px) {
        width: 130px;
      }
    }

    .footer_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;

      @media (max-width: 1440px) {
        gap: 40px;
      }
      @media (max-width: 800px) {
        gap: 20px;
      }

      .icon_color {
        width: 50px;
        height: 50px;
        color: #32474a;

        @media (max-width: 1440px) {
          width: 35px;
        }
        @media (max-width: 800px) {
          width: 25px;
        }
      }
    }

    .line {
      border-bottom: 1px solid #b1b1b1;
      width: 72%;
    }

    .flag {
      display: flex;
      align-items: center;
      width: 72%;
      justify-content: space-between;

      @media (max-width: 1000px) {
        flex-direction: column;
        gap: 2rem;
      }
    }

    .copyright {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;

      display: flex;
      align-items: center;

      color: #393939;

      @media (max-width: 1440px) {
        font-size: 13px;
      }
      @media (max-width: 1055px) {
        font-size: 10px;
      }
    }

    .privacy-policy {
      color: #393939;
      @media (max-width: 1055px) {
        font-size: 13px;
      }
      @media (max-width: 1000px) {
        display: block;
        margin: auto;
      }
    }
  }

  .google_btn {
    width: 154px;
    height: 46px;
    display: flex;
    align-items: center;
    gap: 3px;
    background: #000000;
    border: 1px solid #8a8a8a;
    box-sizing: border-box;
    border-radius: 5px;

    @media (max-width: 1440px) {
      width: 120px;
      height: auto;
    }

    @media (max-width: 800px) {
      width: 100px;
      height: auto;
    }
  }

  .google_btn img {
    width: 30px;
    height: 30px;
    margin-left: 5px;

    @media (max-width: 800px) {
      width: 18px;
      height: 18px;
    }
  }

  .btn_title {
    color: white;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  .btnone {
    position: absolute;
    right: 14%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    top: 20%;
    background: none;
    border: none;
    cursor: pointer;

    @media (max-width: 1000px) {
      top: 13%;
    }
    @media (max-width: 1000px) {
      top: 16%;
    }
    @media (max-width: 600px) {
      position: relative;
      right: unset;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    // @media (max-width: 470px) {
    //   position: absolute;
    //   top: 50%;
    // }
    // @media (max-width: 400px) {
    //   position: absolute;
    //   top: 50% !important;
    // }
  }

  @media (max-width: 1440px) {
    .flag_img img {
      width: 450px;
    }
  }
  @media (max-width: 470px) {
    .flag_img img {
      width: 300px;
    }
  }
}
