@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.height {
}

.r_flex {
  background: url(../Images/benefitbg.svg);
 
  background-repeat: no-repeat;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  border-bottom-left-radius: 80px;
  background-position: center;
 
  
  max-width: 1200px;
  height: 100vh;
  min-height: 705px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  @media (min-width: 1200px) {
    background-size: contain;
  }
  @media (max-width: 1199px) {
    padding-bottom: 130px;
    background-size: contain;
  }
  @media (max-width: 930px) {
    height: auto;
  }
  @media (max-width: 900px) {
    background-size: cover;
    height: auto;
  }
  @media (max-width: 400px) {
    min-height: 100vh;
    height: 100%;
    margin-bottom: 10px;
  }
}

.main_benefit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
// padding-top: 100px;
  @media (max-width: 930px) {
    flex-direction: column;
  }
}

.beneimg {
 margin-top: 20px;
  @media (min-width: 1200px) {
    width: 500px;
    height: 400px; 
  
  
  }
  @media (max-width: 1199px) {
    width: 325px;
    height: 325px;
  }
  @media (max-width: 500px) {
    width: 350px;
    height: 300px;
  }
  @media (max-width: 400px) {
    width: 300px;
    height: 250px;
  }
}
.card_1 {
  position: relative;
  width: 220px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 20px 99px rgba(0, 0, 0, 0.06);
  border-radius: 5px 5px 25px 5px;

  @media (max-width: 1300px) {
    width: 170px;
    height: 180px;
  }

  @media (max-width: 400px) {
    width: 140px;
    height: 150px;
  }
}

.card_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  background-color: white;
  border: 1px solid #6f9f72;
  position: absolute;
  top: -48px;
  border-radius: 50%;

  @media (max-width: 1300px) {
    width: 60px;
    height: 60px;
    top: -35px;
  }

  @media (max-width: 400px) {
    width: 40px;
    height: 40px;
    top: -22px;
  }

  .loc {
    // width: 55.8px;
    // height: 58.52px;

    @media (max-width: 1300px) {
      width: 30px;
      height: 30px;
    }
    @media (max-width: 400px) {
      width: 20px;
      height: 20px;
    }
  }
}
.bene_bg {
}
.bene_card {
  display: flex;
  gap: 50px;
  justify-content: center;
}
.bene_card2 {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-left: 300px;
  margin-top: 67px;

@media (min-width: 1200px) {
  margin-bottom: 80px;
  }
  @media (max-width: 930px) {
    margin-left: unset;
    margin-top: 50px;
  }
}

.card_1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card_para {
  max-width: 84%;
  text-align: left;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;

  display: flex;
  align-items: center;
  color: #000000;
  @media (max-width: 1300px) {
    max-width: 80%;
    line-height: 20px;
    font-size: 12px;
  }
  @media (max-width: 400px) {
    max-width: 80%;
    line-height: 17px;
    font-size: 9px;
  }
}

.for_need {
  display: flex;

  flex-direction: column;
  gap: 100px;
  @media (min-width: 1200px) {
    height: 400px;
    justify-content: end;
    margin-left: 70px;
    padding-top: 258px;
  }
  @media (max-width: 1199px) {
    margin-top: 100px;
  }
  @media (max-width: 930px) {
    gap: 50px;
  }
}

.bene_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #000000;
  @media (max-width: 1300px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
}
