@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main_navbar {
 
  background-color: #fff3e9;
  box-shadow: 0px 30px 99px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 54656656565656565;
width: 100%;
@media (min-width: 1400px) {
  height: 105px;
}
@media (max-width: 1000px) {
  justify-content: space-between;
  
}
@media (max-width: 1400px) {
  justify-content: space-between;height: 80px;
}

  .nav_logo img {
    @media (max-width: 1400px) {
      width: 120px;
      height: 40px;
    }
    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }
  .nav_text {
    display: flex;
    gap: 33px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;

    @media (max-width: 1270px) {
      gap: 20px;
      font-size: 13px;
    }
    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 1250px) {
      font-size: 13px;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
}

.nav_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .nav_title {
    font-family: "PT Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;

    color: #32474a;
    @media (max-width: 1250px) {
      font-size: 20px;
    }
  }
}
.nav_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_button {
  background: #32474a;
  box-shadow: 0px 15px 60px rgba(232, 80, 91, 0.1);
  border-radius: 5px;
  width: 193px;
  height: 53px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  outline: none;
  @media (max-width: 1250px) {
    width: 130px !important;
    font-size: 13px;
  }
  @media (max-width: 1000px) {
    width: 120px !important;
    height: 40px;
    font-size: 13px;
  }

  @media (max-width: 1000px) {
    top: 20px;
    position: absolute;
    right: 100px;
  }
  @media (max-width: 400px) {
    position: absolute;
    right: 70px;
    font-size: 10px;
    width: 100px !important;
    height: 40px;
  }
}

.ham_nav {
  color: #32474a;
  display: none;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-right: 10px;
    display: block;
  }
}

.navbar__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.items {
  font-size: 15px;
  color: #32474a;
  cursor: pointer;
}
.homedesign {
  width: 63px;
  height: 2px;
  margin-left: -10px;
  background: linear-gradient(
    90deg,
    rgba(50, 71, 74, 0.01) 1.85%,
    #32474a 50.04%,
    rgba(50, 71, 74, 0.02) 96.91%
  );
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
  color: #000000;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(50, 71, 74, 0.01) 1.85%,
    #32474a 50.04%,
    rgba(50, 71, 74, 0.02) 96.91%
  );

  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.logo_img{
  @media (min-width: 1400px) {
    width: 193px;
    height: 50px;
  }
  @media (min-width: 1400px) {
    
  }
  @media (min-width: 1400px) {
    
  }


}