@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.sol_centre{
display: flex;
align-items: center;
justify-content: center;
min-height: 100vh;
height: 100%;
// margin-bottom: 200px;

@media(max-width:1000px){
    margin-top: 35px;
}
@media(max-width:700px){
    margin-top: 35px;
}
@media(max-width:550px){
    margin-top: 35px;
}
@media(max-width:470px){
    margin-top: 35px;
}
@media(max-width:400px){
    margin-top: 35px;
}

.main_sol {
  display:flex;
  justify-content: center;
  align-items: center;
  @media (max-width:950px){
    flex-direction: column;
    gap: 4rem;
}
  .sol_text {
    margin-left: 82px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    position: relative;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    display: flex;
    max-width: 27%;
    flex-direction: column;
    @media (max-width:1440px){
        max-width: 40%;
    }
    @media (max-width:950px){
        max-width: 70%;
        margin-left: unset;
    }
    @media (max-width:550px){
        max-width: 70%;
    }
    @media (max-width:1440px){
        font-size: 25px;
    }

  }

  .sol_para  {
    
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    color: #202020;

    @media (max-width:1440px){
        font-size: 16px;
    }
    @media (max-width:900px){
        font-size: 14px;
    }
  }


.sol_img{
    @media (max-width:1440px){
        width: 600px;
    }
    @media (max-width:1200px){
        width: 500px;
    }
    @media (max-width:550px){
        width: 300px;
    }
}





.sol_logo img{
    position: absolute;
    top: -220.16px;
    right: 70px;

    @media (max-width:990px){
        top: -50px;
        width: 80px;
    }
    @media (max-width:470px){
        right: -50px;
        top: -60px;
    }
}




.sol_circle img{
    position: absolute;
    bottom: -290px;
    right: 0;
@media(max-width: 990px){
    display: none;
    width: 120px;
    bottom: -80px;
    right: -60px;
}
@media(max-width: 940px){
    display: block;
    width: 120px;
    bottom: -80px;
    right: -60px;
}
@media(max-width: 400px){
    display: none;
   
}
}


}



}



















