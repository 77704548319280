.flow {
  position: relative;
  margin-top:60px;
  .maintall{
    //   height: 80vh !important;
    @media screen and (max-width: 500px){
      padding-bottom: 30px;
    }
  }

  .partyheading {
    position: absolute;
    left: 49%;
    top: 13%;
    transform: translate(-55%, -18%);
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 43px;
    color: black;
  }
  .make {
    @media (max-width: 500px) {
      margin-top: 20px !important;

      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      width: 100% !important;
      align-items: center !important;
    }
  }
  .bollimg {
    height: 80px;
    width: 80px;
  }
  .bollimg {
    position: absolute;
    right: 0;
    bottom: -169px;
  }
  .bollimgdiv {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }
  .imageofbuttonsdiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 60px;
    position: relative;

    .hostbtns{
      margin-top: 0;
    }

    @media (max-width: 500px) {
      justify-content: center;
      width: 100%;
    }
  }

  .imageofbuttons {
    width: 357px;
    // margin-right: 50px;
    height: 46px;
    margin-top: -140px;
    position: absolute;
    right: 0px;
    bottom: 35px;
  }
  .backpottimg {
    background-image: url(../../Page2/Images/blue.svg);
    background-position: center;
    background-size: cover;
    // height: 730px;
    // height: 80vh !important;
    @media(max-width: 600px)
    {
      height: 100vh  !important;
    }
  }
  // .headerimg {
  //   width:65% !important;
  //   // height: 75vh;
  // object-fit: contain
  // }
  .headermbl {
    width: 100%;
    // max-width: 349px;
    // height: 80vh;
    // object-fit: contain;

    @media (max-width: 500px) {
      display: none;
    }
  }
  .headermbldiv {
    align-items: center;
    position: absolute;
    text-align: center;
    bottom: -48px;
    left: 6%;
  }
.headerimgdiv{
  object-fit: cover;
  @media (min-width: 601px) {
    display: flex;
    justify-content: center;
    min-height: 575px;
    height: calc(100vh - 199px);
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    height: 85vh;
  }
  
}

  @media screen and (max-width: 1500px){
    .headermbl{
      max-width: 350px;
    }
  }
  @media only screen and (min-width: 1441px) {
    //   .backpottimg {
    //     // height: 752px;
    // }
    .partyheading {
      position: absolute;
      left: 49%;
      top: 13%;
      transform: translate(-55%, -18%);
      font-family: "Inter";
      font-style: normal;
      font-weight: bold;
      font-size: 49px;
      line-height: 12px;
      color: #000000;
    }
    .imageofbuttons {
      width: 357px;
      // margin-right: 6px;
      height: 46px;
      margin-top: -100px;
    }
    .headerimg {
      width: 100%;
      object-fit: cover;
      // height: 100vh;
      // object-fit: contain;
    }
    .headermbl {
      width: 100%;
      // max-width: 242px;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -100px;
      left: 6%;
    }
  
  }
  @media only screen and (min-width: 1440px) {
    .bollimg {
      position: absolute;
      right: 0;
      bottom: -55px;
    }
   
  }
  @media only screen and (max-width: 1440px) {
    .partyheading {
      position: absolute;
      left: 49%;
      top: 13%;
      transform: translate(-35%, -85%);
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      color: #000000;
    }
    .bollimg {
      position: absolute;
      right: 0;
      bottom: -55px;
    }
    .headerimg {
      width: 100%;
      // max-width: 900px;
      // height: 100vh;
      // object-fit: contain;
    }
    .headermbl {
      width: 100%;
      max-width: 265px;
      // margin-top: 50px;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
  }
  @media only screen and (max-width: 1250px) {
    .headerimg {
      width: 100%;
      // max-width: 750px;
      // height: 97vh;
    }
    .headermbl {
      width: 100%;
      max-width: 242px;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
  }
  @media only screen and (max-width: 1150px) {
    .headerimg {
      width: 100%;
      // max-width: 700px;
      // height: 100vh;
    }
    .headermbl {
      width: 100%;
      max-width: 242px;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .backpottimg {
      background-image: url(../../Page2/Images/blue.svg);
      background-position: center;
      background-size: cover;
      height: auto;
    }
    .headerimg {
      width: 100%;
      // height: auto;
      // height: 100vh;
    }
    .headermbl {
      width: 100%;
      max-width: 200px;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
    .headerimgdiv{
      min-height: 420px;
    }
  }
  @media only screen and (max-width: 850px) {
    .bollimg {
      display: none !important;
    }
    .headermbldiv {
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
   
  }

  @media only screen and (max-width: 767px) {
    .backpottimg {
      flex-direction: column;
      background-position: center;
      background-size: cover;
    }
    .imageofbuttonsdiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
    }
   
    .headermbldiv {
      
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      right: 10%;
      display: grid;
      left: unset;display: none;
    }
    .headerimg {
      width: 100%;
      max-width: initial;
      // max-width: 700px;
      // height: 100vh;
    }
    .headerimgdiv{
      min-height: 440px;
    }
    .headermbl {
      width: 100%;
      max-width: 150px;
    }
  }
  @media only screen and (max-width: 600px) {
    .headermbldiv {
      display: none;
      align-items: center;
      position: absolute;
      text-align: center;
      bottom: -48px;
      left: 6%;
    }
    .headerimg {
      width: 100%;
      object-fit: cover;
      // max-width: 340px;
      // height: 55vh;
    }
    .headermbl {
      width: 100%;
      max-width: 150px;
    }
   
  }
  @media only screen and (max-width: 600px) {
    .imageofbuttons {
      width: 222px !important;
      height: 27px !important;
      object-fit: cover;
      margin-top: 0px !important;
      margin-left: 73px !important;
      bottom: 0px !important;
      position: relative;
      right: unset;
    }
    .headermbldiv {
      align-items: center;
      position: relative;
      text-align: center;
      top: 0px;
      margin-bottom: 30px;
    }
    .headerimg {
      width: 100%;
      // max-width: 300px;
      // height: 55vh;
    }
    .headermbl {
      width: 100%;
      max-width: 130px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .flow {
      margin-top: 30px !important;
    }
    .imageofbuttons {
      width: 300px;
      height: 40px;
      margin-top: -130px;
      margin-right: 62px;
    }
    .partyheading {
      position: absolute;
      left: 53%;
      top: 3%;
      transform: translate(-50%, 60%);
      font-weight: 700;
      font-size: 40px;
      line-height: 58px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .partyheading {
      position: absolute;
      left: 54%;
      top: 3%;
      transform: translate(-50%, 60%) !important;
      font-weight: 700;
      font-size: 37px;
      line-height: 58px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .imageofbuttons {
      width: 280px;
      height: 35px;
      margin-top: -110px;
    }
    .flow {
      margin-top: 15px !important;
    }
    .partyheading {
      position: absolute;
      top: 3%;
      transform: translate(-52%, 64%) !important;
      font-weight: 700;
      font-size: 25px;
      line-height: 58px;
    }
  }
  @media only screen and (max-width: 991px) {
    .imageofbuttons {
      width: 250px;
      height: 30px;
      margin-top: -100px;
    }
    .partyheading {
      position: absolute;
      top: 0;
      transform: translate(-54%, 150%);
      font-weight: 700;
      font-size: 30px;
      line-height: 58px;
    }
  }
  @media only screen and (max-width: 991px) {
    .partyheading {
      position: absolute;
      top: 0;
      transform: translate(-50%, 150%);
      font-weight: 700;
      font-size: 25px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 555px) {
    .flow {
      margin-top: 10px !important;
    }
    .partyheading {
      position: absolute;
      top: 3%;
      transform: translate(-50%, 520%);
      font-weight: 700;
      font-size: 25px;
      line-height: 40px;
    }
    .partyheading {
      position: absolute;
      top: 35px;
      font-weight: 600;
      line-height: 20px;
      left: 0;
      right: 0;
      transform: none!important;
      text-align: center;
      margin-top: 40px;
      font-size: 25px;
    }
  }
  @media only screen and (max-width: 375px) {
    .partyheading {
      position: absolute;
      top: 35px !important;
      transform: none;
      font-weight: 600;
      // font-size: 15px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 520px) {
    // .imageofbuttons {

    // }
    .partyheading {
      position: absolute;
      top: 3%;
      transform: translate(-50%, 330%);
      font-weight: 600;
      // font-size: 15px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 1884px) and (min-width: 1500px) {
    .imageofbuttonsdiv {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 90px;
      // margin-right: 180px;
    }
  }
}
