@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.centre_hav {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 900px) {
    height: auto;
    margin-bottom: 107px;
  }

  @media (max-width: 430px) {
    margin-top: 60px;
  }

  .main_hav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 2rem;
    }

    .hav_text {
      margin-right: 66px;
      max-width: 27%;
      position: relative;
      display: flex;
      justify-content: center;

      flex-direction: column;
      @media (max-width: 1560px) {
        max-width: 35%;
      }
      @media (max-width: 1200px) {
        margin-left: 20px;
        max-width: 40%;
      }
      @media (max-width: 900px) {
        margin-right: unset !important;
        max-width: 70%;
      }
      @media (max-width: 550px) {
        max-width: 90%;
      }
      .hav_title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #000000;

        @media (max-width: 1200px) {
          font-size: 25px;
        }
        @media (max-width: 360px) {
          font-size: 20px;
        }
      }

      .hav_para {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        color: #202020;
      }

      .hav_logo_img img {
        position: absolute;
        top: 269px;
        @media (max-width: 900px) {
          width: 80px;
          top: 230px;
        }
        @media (max-width: 670px) {
          top: 230px;
        }
        @media (max-width: 620px) {
          top: 230px;
        }
        @media (max-width: 420px) {
          top: 270px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .hav_img img {
      width: 500px;
    }
  }
  @media (max-width: 550px) {
    .hav_img img {
      width: 400px;
    }
  }
  @media (max-width: 420px) {
    .hav_img img {
      width: 300px;
    }
  }
}
