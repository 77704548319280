@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.maincontentdiv {

  background: url(../Images/backgroundguys.svg);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  border-bottom-left-radius: 80px;
  background-position: center;
 background-repeat: no-repeat;
  
  max-width: 1250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  margin: auto;
  @media (min-width: 1200px) {
    background-size: contain;
  }
  @media (max-width: 1199px) {
    padding-bottom: 130px;
  }
  @media (max-width: 900px) {
    background-size: cover;
    height: auto;
    padding-bottom: 270px !important;

  }
  @media (max-width: 400px) {
    min-height: 100vh;
    height: 100%;
    margin-bottom: 10px;
    padding-bottom: 270px;
  }
}
.bollofcrowdimage {
  height: 89px;
  width: 89px;
}
.bollofcrowd {
  position: absolute;
  right: 55px;
  bottom: -431px;
}

.maincant {
  align-items: center;
  align-items: flex-start;
  display: inline-grid;
  display: flex;
  justify-content: center;

  /* width: 50%; */
  /* max-height: 1051px; */
  // height: 100%;
}
.bollofcrowdimage {
  height: 89px;
  width: 89px;
}
.bollofcrowd {
  position: absolute;
  right: -44px;
  bottom: -430px;
}
.MuiGrid-spacing-xs-2 {
  width: calc(100% + 0px) !important;
  margin: -8px !important;
}
.mainheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}
.crowdheading {
  margin: auto;
  text-align: center;
}
.mainof {
  margin: auto;
  text-align: center;
  margin-top: 90px;
  display: inline-block;
  margin-top: 50px;
}
.mainimages {
  display: flex;
  justify-content: center;
}
.guysdiv {
  margin: auto;
}
.guys {
  max-width: 664px;
  width: 70%;
  height: 80%;
  margin-left: 34px;
  margin-top: 30px;
}
.boxmaindiv {
  display: flex;
  gap: 20px;
}
.boxdiv {
  position: relative;
  margin-top: 70px;
}
.boxseconddiv {
  position: relative;
  margin-top: 40px;
}
.circle {
  height: 70px;
  width: 70px;
  border-radius: 35px;
  background-color: white;
  position: absolute;
  background: #ffffff;
  border: 1px solid #89aa8b;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 96px;
  top: -34px;
  align-items: center;
}
.rectang {
  background-image: url(../Images/rect.svg);
  height: 180px;
  width: 220px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px 5px 25px 5px;
  display: flex;
  justify-content: center;
}
.hand {
  width: 48px;
  height: 48px;
}
.caland {
  width: 48px;
  height: 48px;
}
.belowcartdiv {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.men,
.face,
.grop {
  width: 48px;
  height: 48px;
}
.rectanheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  color: #000000;
  margin: auto;
  text-align: left;
  padding: 30px;
}

/* mediaquery */
@media only screen and (max-width: 1440px) {
  .mainheading {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
  .rectang {
    height: 180px;
    width: 220px;
    max-width: 100%;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
    /* margin-left: 70px; */
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 33px;
    left: 70px;
    top: -34px;
  }
  .rectanheading {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 1350px) {
  .rectanheading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .rectang {
    height: 180px;
    width: 220px;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
  
    /* margin-left: 70px;
     */
  }
  .circle {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    left: 70px;
    top: -34px;
  }
  .men,
  .face,
  .grop,
  .caland,
  .hand {
    width: 40px;
    height: 40px;
  }
  .rectang {
    height: 160px;
    width: 200px;
    border-bottom-right-radius: 15px;
  }
  .mainof {
    margin: auto;
    text-align: center;
    margin-top: 90px;
    display: inline-block;
    margin-top: 100px;
  }
}
@media only screen and (max-width: 1130px) {
  .mainof {
    margin: auto;
    text-align: center;
    margin-top: 90px;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
  }
}
@media only screen and (max-width: 1530px) {
  .mainof {
    margin: auto;
    text-align: center;
    margin-top: 120px;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
  }
}
@media only screen and (max-width: 1350px) {
  .rectanheading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .men,
  .face,
  .grop,
  .caland,
  .hand {
    width: 40px;
    height: 40px;
  }
  .rectang {
    height: 160px;
    width: 200px;
    border-bottom-right-radius: 15px;
  }
  .circle {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    left: 70px;
    top: -34px;
  }
  .rectang {
    height: 150px;
    width: 190px;
    border-bottom-right-radius: 12px;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
    /* margin-left: 70px; */
  }
}
@media only screen and (max-width: 991px) {
  /* .bollofcrowd {
    position: absolute;
    right: -44px;
    bottom: -705px;
  } */
  .maincant {
    align-items: center;
    gap: 0px !important;
  }
  .guysdiv {
    justify-content: center;
    margin: 10px auto;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .mainof {
    margin-top: 50px !important;
  }
  .guysdiv {
    margin: 10px auto;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
  }

  .rectanheading {
    font-weight: 400px;
    font-size: 12px;
    line-height: 18px;
  }
  .belowcartdiv {
    margin: auto;
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .boxseconddiv {
    position: relative;
    margin-top: 40px !important;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 1008px) {
  .boxmaindiv {
    margin-bottom: 20px;
  }
  .mainheading {
    margin-bottom: -10px;
  }
  .maincant {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .guys {
    max-width: 664px;
    width: 70%;
    height: 80%;
    margin-left: 34px;
    margin-top: 30px;
    
  }

  .maincant {
    flex-direction: column;
    text-align: center;
    margin: 10px auto;
    gap: 0px;
  }
  .mainof {
    margin-top: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 425px) {
  .boxmaindiv {
    flex-direction: column;
  }
  .mainheading {
    font-weight: 500px;
    font-size: 15px;
    line-height: 26px;
  }
  .guys {
    width: 130%;
    /* margin-right: 20px !important; */
  }
}
@media only screen and (max-width: 768px) {
  .bollofcrowd {
    display: none;
  }
  .mainimages {
    margin-bottom: 20px;
  }
  .guys {
    width: 120%;
  }
  .circle {
    left: 70px;
  }
  .mainof {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .mainheading {
    font-weight: 700;
    font-size: 25px;
  }
}

@media only screen and (max-width: 690px) {
  .mainimages {
    margin-bottom: 0px;
  }
  .belowcartdiv {
    flex-direction: column;
    margin-top: 15px !important;
    display: grid;
  }
  .boxmaindiv {
    flex-direction: column;
    gap: 0px;
  }
  .rectang {
    height: 210px;
    width: 260px;
  }
  .circle {
    left: 100px;
  }
}
@media only screen and (max-width: 1340px) {
  /* .bollofcrowd {
    position: absolute;
    right: -44px;
    bottom: -430px;
  } */
}
.align_item_end{
  min-height: 400px;
  @media(min-width:950px){
    display: flex;
    align-items: flex-end;
  }
}