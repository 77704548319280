@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.flex_n {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  
}

.z_in{
  position: relative;
  @media(max-width:1130px){
    width: 100%;
  }
}
.line_bg {
  position: relative;
  @media(max-width:1440px){
  margin-top: 50px;
    
  }
   .b_line{
     height: 177.13px;
     width: 2212.15px;
     
    }
  
  }
  
  .b_logo{
    position:absolute;
    right: 150px;
    top: 0px;
  
  @media(max-width:1440px){
    width: 100px;
    height: 100px;
    top: 80px;
    
  }
  
    @media(max-width:1130px){
      display: none;
    }
  }
  
.main_sub {
  background: #fff3e9;
  border-radius: 120px 10px;
  margin-top: 100px;
  width: 1330px;
  margin-bottom: 100px;
  // background-repeat: no-repeat;
  // background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 380px;
  position: relative;
  flex-direction: column;
  z-index: 9999;
  @media (max-width: 1560px) {
    width: 1100px;
  }
  @media (max-width: 1332px) {
    width: 1000px;
  }
  @media (max-width: 1230px) {
    width: 900px;
  }
  @media (max-width: 1130px) {
    width: 100%;
    border-radius: 0;
  }

  @media (max-width: 1000px) {
    height: 400px !important;
    margin-top: 30px;
    background-size: cover;
    border-radius: 0;
    margin-bottom: 30px;
  }

  .circlea {
    position: absolute;
    left: -186px;
    bottom: -82px;
    @media (max-width: 1130px) {
      display: none;
    }
  }

  .relate {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  .up {
    position: absolute;
    left: 110px;
    height: 380px;
    width: 548px;
    top: 0;
    @media (max-width: 1250px) {
      width: 448px;
      height: 380px;
      object-fit: cover;
    }
    @media (max-width: 930px) {
      width: 348px;
      height: 380px;
      object-fit: cover;
    }
    @media (max-width: 800px) {
      left: 50px;
      width: 248px;
      height: 280px;
      object-fit: cover;
      top: 44px;
    }
    @media (max-width: 550px) {
      width: 148px;
      height: 180px;
      object-fit: cover;
      left: 25px;
    }
  }
  .down {
    position: absolute;
    right: 160px;
    top: 0;
    height: 380px;
    width: 548px;

    @media (max-width: 1250px) {
      width: 448px;
      height: 380px;
      object-fit: cover;
    }
    @media (max-width: 800px) {
      width: 248px;
      height: 280px;
      right: 80px;
      object-fit: cover;
      top: 44px;
    }
    @media (max-width: 730px) {
      width: 248px;
      height: 280px;
    }
    @media (max-width: 550px) {
      right: 25px;
      width: 148px;
      height: 180px;
    }
  }

  .sub_img_sec {
   
  }

  .sub_text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .sub_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      color: #363636;

      @media (max-width: 1440px) {
        font-size: 25px;
      }
    }

    .sub_para {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 36px;
      color: #2d2d2d;
      max-width: 95%;
      align-items: center;
      text-align: center;
      @media (max-width: 1440px) {
        font-size: 15px;
      }
    }
  }

  .input_bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 713px;
    height: 78px;
    background: #ffffff;
    box-shadow: 0px 20px 99px rgba(0, 0, 0, 0.06);
    border-radius: 70px;

    @media (max-width: 730px) {
      width: 543px;
      height: 60px;
    }

    @media (max-width: 600px) {
      width: 436px;
      height: 50px;
    }
    @media (max-width: 500px) {
      width: 280px;
      height: 50px;
    }

    ::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: #767676;

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    .input_t {
      width: 465px;
      height: 78px;
      background: #ffffff;
      box-shadow: 0px 20px 99px rgba(0, 0, 0, 0.06);
      border-radius: 70px;
      border: none;
      outline: none;
      padding-left: 40px;
      font-size: 25px;

      @media (max-width: 730px) {
        width: 380px;
        padding-left: 10px;
        font-size: 20px;
        height: 60px;
      }
      @media (max-width: 600px) {
        width: 280px;
        height: 50px;
      }
      @media (max-width: 500px) {
        padding-left: 20px;
        width: 180px;
        height: 50px;
        font-size: 15px;
      }
    }
  }

  .sub_btn {
    width: 228px;
    height: 56px;
    background: #32474a;
    box-shadow: 0px 4px 200px rgba(0, 0, 0, 0.06);
    border-radius: 70px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    border: none;
    line-height: 40px;
    justify-content: center;
    gap: 2rem;

    display: flex;
    align-items: center;

    color: #ffffff;

    @media (max-width: 730px) {
      width: 170px;
      height: 45px;
    }

    @media (max-width: 730px) {
      width: 140px;
      height: 42px;
      gap: 1rem;
      font-size: 12px;
    }
    @media (max-width: 600px) {
      height: 38px;
    }
    @media (max-width: 500px) {
      width: 90px;
      height: 35px;
      gap: 10px;
      font-size: 9px;
    }
  }
  .arrow {
    width: 20px;
    height: 20px;

    @media (max-width: 500px) {
      width: 14px;
      height: 14px;
    }
  }
}

.back {
  position: absolute;
  right: -78px;
  top: 56px;
  z-index: 1111;
    @media (max-width:1130px){
      display: none;
    }
  
}
